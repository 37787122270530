import domReady from '@wordpress/dom-ready';
import $ from 'jquery';

domReady(() => {
    const sliders = $('.youtube-slider');
    sliders.slick({
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 490,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    });
});
